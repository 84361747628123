.react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {
    display: none;
}

.react-horizontal-scrolling-menu--scroll-container {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

.react-horizontal-scrolling-menu--item .active {
    cursor: pointer;
    color: #5569ff;
    background: white;
    border-radius: 10px;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-right: 6px;
    padding-left: 6px;
}

.hor-scroll {
    position: sticky;
    top: 60px;
    background: #5569ff;
    justify-content: center;
    height: 40px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;

}
